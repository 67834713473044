<template>
  <div>
    <div class="divider-full"></div>
    <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <h1 class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Doa bepergian/safar</h1>
      </div>
    </div>
    <div class="cotainer-standar container-res-1440">
      <div class="container-standar mt-10 mb-10 pad-20">
        <div class="row">
          <div class="col-lg-8 col-md-8 pad-20 berita-content card shadow">
            <div role="tablist" class="">
              <b-card no-body class="mb-10"  v-for="(items, i ) in datadoa" :key="i">
                <b-card-header header-tag="header" class="" role="tab" >
                  <b-button block href="#"  v-b-toggle="'accordion-' + items.id" class="btn-overide">
                    {{ i + 1 }}. {{items.title}}
                    <i class="fa fa-angle-right text-primary float-right"></i>
                  </b-button>
                </b-card-header>
                <b-collapse  :id="'accordion-'+items.id"  visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text
                      class="font-18 font-weight-4 text-grey text-center"
                    >
                      <div class="content-this-html"  v-html="items.content">  
                      </div>
                      <p>
                        <br>
                        <br> 
                        <center>
                        <audio controls="">
                        <source v-bind:src="'https://storage.realtravel.co.id/audio/'+items.audio">
                        Your browser does not support the audio element.
                        </audio>
                        </center>
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card> 

            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="card shadow text-left pad-10">
              <span
                class="d-inline-block bor-bot-1 mb-10 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
              >Panduan Umroh Lainnya</span>
             
                <div class="suggest-img cursor-pointer" v-for="(items, index) in panduan" :key="index" > 
                  <span @click.prevent="linkto(items)" class="title d-inline-block width-100 font-14 font-weight-6">
                    {{items.name}} <i class="fa fa-angle-right float-right "></i>
                  </span>
                </div>
         
                
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import SocialSharing from "vue-social-sharing";
import stripHtml from "string-strip-html";
export default {
  components: {
    // SocialSharing
  },
  data() {
    return {
      datadoa:[],
      panduan:[]
    };
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getData()
    this.getDataside()
  },
  methods: {
    getData(){
      this.axios.get('https://admin.realtravel.co.id/view/panduan_submenu/'+ this.$route.params.id)
      .then(response => {
        let res = response.data
        this.datadoa = res
        // console.log(res)
      })
    },
      getDataside(){  
        this.axios.get('https://admin.realtravel.co.id/view/panduan_menu')
       
        .then( response => { 
              let res = response.data
          this.panduan = res
        // console.log(res)
        //  postsa.length = this.dataLength
        }).catch( e =>{
          // console.log(e);
          this.err.push(e)
        });
        // console.log();
    },
      content(val){
      return stripHtml(val);
    },
       linkto(val){
      this.$router.push('/doa-umroh/'+ val.id)
      setTimeout(() => { 
      location.reload()
      }, 500);
    }
  },
};
</script>